.sub-nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  list-style-position: outside;
  padding: 0 4%;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  align-self: flex-end;
  li {
    margin-left: 5px;
    cursor: pointer;
    opacity: 0.9;
    width: max-content;
    font-size: 14px;
    padding: 2% 0%;
    min-width: 130px;
    font-weight: 600;
    &:hover , &.selected {
      opacity: 1;
      background: rgba(0, 0, 0, 0.213);
      border-radius: 5px;
    }
  }
}

.expenses-wrapper {
  display: flex;
  width: 100%;
  margin: 2% 2.2% 0;
  flex-direction: column;
  flex-wrap: wrap;
}

.expense-table {
  width: 100%;
  .head {
    .row {
      // font-weight: 800;
      font-size: 14px;
    }
  }
  .body {
    .row {
      font-size: 13px;
    }
  }
}

