.signup-form {
  margin: 50px 0;
  max-width: 400px;
  width: 80% !important;
}

.signup-form p {
  font-size: 12px;
}

.signup-form b, .signup-form a {
  color: rgb(123, 134, 203);
  text-decoration: none;
}


.input-grp-wrapper {
  width: 100%;
  display: flex;
}



@media only screen and (max-width: 600px) {
  .input-grp-wrapper {
    flex-direction: column;
  }
  
  .input-grp-wrapper .input-wrapper {
    width: 100%;
  }
}


@media only screen and (min-width: 600px) {
  .signup-form {
    /* width: 60% !important; */
    /* max-width: unset !important; */
    margin: 50px 0;
  }
  .input-grp-wrapper {
    justify-content: space-between;
  }
  
  .input-grp-wrapper .input-wrapper {
    width: 48%;
  }
}