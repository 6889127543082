.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;
  position: relative;
}


.input-wrapper input, .input-wrapper select, .input-wrapper textarea {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 10px;
  border: 1px solid #b4b4b5;
  background: #FCFDFE !important;
  color: #66676d;
  font-size: 14px;
  option {
    text-transform: capitalize;
  }
  &::placeholder {
    opacity: 0.7;
    color: #66676dc5;
  }
}

.input-wrapper > .label-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  position: relative;
  .open-info {
    width: 15px;
    height: 15px;
    align-self: flex-start;
  }
  .info {
    font-size: 12px;
    width: 250px;
    height: 100px;
    border-radius: 8px;
    background: #fff !important;
    position: absolute;
    opacity: 1 !important;
    right: 0;
    top: -110px;
    z-index: 9999999;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center !important;
    // font-weight: 400;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 50px 0px;
    &::before {
      background: #fff;
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -5px;
      right: 5px;
      transform: rotate(45deg);
    }
  }
}

.input-wrapper label {
  color: #66676D;
  font-size: 14px;
  // font-weight: bold;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.input-wrapper a {
  text-decoration: none !important;
}

.input-wrapper span {
  color: #9FA2B4;
  font-size: 10px;
  // font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}

.input-wrapper p {
  color: red;
  font-size: 10px;
  // font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}

.open-password {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  right: 3px;
  top: 18px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
}

.cta-label {
  font-size: 12px !important;
  text-decoration: underline;
  // font-weight: bold !important;
  cursor: pointer;
  opacity: 0.7;
  transition: all 1ms;
  color: red !important;
  &:hover {
    opacity: 1;
  }
}
