@import "/src/utils/breakpoints";


.overview {
  display: flex;
  min-height: 100%;
  width: 100% !important;
  flex-wrap: wrap;
  box-sizing: border-box;
  .overview-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 80vh;
    box-sizing: border-box;
    .recent-expense {
      width: 100%;
      border-radius: 5px;
      background: #FFF;
      height: 75vh;
      text-align: left;
      padding: 20px 0;
      box-sizing: border-box;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @include tab {
        width: 100%;
        margin-bottom: 1rem;
        height: fit-content;
      }

      @include mobile {
        padding: 20px 7%;
      }

      h5 {
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
      }
      .expense-table {
        width: 100%;
        overflow: auto;
        .head {
          .row {
            // font-weight: 800;
            font-size: 14px;
          }
        }
        .body {
          .row {
            font-size: 13px;
          }
        }
        .row {
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 4px 0;
          // background: #ece0d198;
          padding: 0 10px;
          box-sizing: border-box;
          // border-radius: 5px;
          .sn {
            width: 5%;
          }
          .title {
            width: 35%;
          }
          .amount {
            width: 20%;

            @include mobile {
              display: none;
            }
          }
          .status {
            width: 15%;
          }
          .action {
            width: 20%;
          }
          div {
            text-align: left;
            align-items: flex-start;
          }
        }
      }
    }
  }
  .overview-right {
    margin: 0 !important;
    height: max-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-wrap: wrap;
    .overview-card-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      flex-wrap: wrap;
      box-sizing: border-box;
    }
    .card {
      width: 33%;
      height: 180px;
      border-radius: 8px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      box-sizing: border-box;
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      @include tab {
        height: fit-content;
        margin-bottom: 1rem;
        min-width: 250px;
        width: 100%;
      }

      @include mobile {
        min-width: 250px;
        width: 100%;
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-bottom: 35px;
      }
      p {
        text-align: left;
      }
    }
    @include tab {
      flex-direction: column;
    }
    .a-expense-graph {
      width: 100%;
      border-radius: 8px;
      background: #FFF;
      height: 45vh;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      box-sizing: border-box;
      padding: 20px;
      position: relative;

      @include tab {
        width: 100%;
        height: 30vh;
        margin-bottom: 1rem;
      }

      .graph {
        width: 100%;
        height: 100%;
        position: relative;
      }
      p {
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffffbc;
        font-weight: 900;
        border-radius: 8px;
      }
    }
  }
}



.overview .card span {
  font-size: 24px;
  font-weight: 500;
}

.overview .card p {
  font-size: 20px;
  font-weight: 300;
  margin: 5px 0;
}


@media only screen and (max-width: 600px) {
  .overview {
    justify-content: center;
    flex-direction: row !important;
  }
  .overview .card {
    margin-bottom: 20px;
    width: 100%;
  }
  .overview-right {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 5%;
  }
  .overview-left {
    width: 100%;
    padding: 0 5%;
  }
}


@media only screen and (min-width: 600px) {
  .overview {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
  .overview-right {
    min-height: 80vh;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1%;
    padding-bottom: 1%;
    padding-left: 4%;
  }
  .overview-left {
    width: 50%;
    padding: 0 1%;
    padding-right: 4%;
  }
}

.not-found {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  p {
    margin: 5px 0;
    font-size: 12px;
  }
  button {
    width: max-content;
    margin: 5px 0;
  }
}
