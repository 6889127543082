@import "/src/utils/breakpoints";
.how-to-tree {
    &:nth-child(even) {
        display: flex;
        text-align: left;
        .details {
            float: left;
            left: 30px;
        }
    }
    &:nth-child(odd) {
        // float: right;
        display: flex;
        text-align: right;
        .details {
            float: right;
            right: 70px;  
        }
    }
}
