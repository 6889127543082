.update-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000f8;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  .update-card {
    max-width: 400px;
    width: 80%;
    height: 200px;
    background: #FFF;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      margin: 0;
    }
    button {
      background-color: brown;
      color: #FFF;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }
}