.dashboard-nav {
  position: relative;
  li {
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }
  div {
    z-index: 0;
    position: absolute;
    height: max-content;
    width: max-content;
    padding: 20px;
    border-radius: 4px;
    right: 0;
    top: 30px;
    background: #fff;
    li {
      display: flex !important;
      margin: 5px 0;
    }
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  .nav-menu {
    @media only screen and (max-width: 768px) {
      display: block;
    }
    
    
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
}

.filter-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0%;
  z-index: 1 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .withdraw-wrapper {
    margin-top: 20px;
    width: max-content;
  }
  .input-wrapper {
    width: 150px;
  }
}
.subnav-dropdown {
  z-index: 99999 !important;
}