.pulse {
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.1);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
	}
}