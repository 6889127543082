
.expense-details {
    display: flex;
    height: 100%;
    padding: 0 4%;
    padding-top: 60px !important;
    position: relative;
    margin-top: 30px;

    .backBtn {
        position: absolute;
        width: max-content;
        top: 30px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            transform: rotate(90deg);
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 35%;
        text-align: left;
        height: 100%;
        border-right: 1px solid rgba(128, 128, 128, 0.074);
        
        h3 {
            color: #252733;
            margin-bottom: 2rem;
        }
    }

    .right {
        margin: 1rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        background-color: #FFFFFF;
        border-radius: 1rem;
        padding: 2rem 1.5rem;
        

        .right-wrapper {
            padding: 0;
            text-align: left;
            display: flex;
            flex-direction: column;
            width: 100%;

            .heading {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                h1 {
                    color: #252733;
                    margin: 0 1.5rem 0 0;
                    text-transform: capitalize;
                }

                h6 {
                    margin-top: 0 !important;
                }
                button {
                    width: 40px;
                    height: 40px;
                    margin-left: 10px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            
    
            small {
                text-transform: capitalize;
                color: #9fa2b4;
                font-weight: 600;
            }

            p {
                font-size: medium;
                color: #252733;
                line-height: 1.4;

                &::first-letter {
                    text-transform: capitalize;
                }

            }
    
            .expense-percentage {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 1rem;
                background-color: #F8FBFF;
                width: fit-content;
                padding: 1rem 2rem;
                border-radius: 1rem;

                h4 {
                    margin: 0 0 0.8rem 0;
                    text-align: left;
                    font-weight: 200;
                }

                .expense-amount {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    margin-bottom: 0.5rem;
                    width: fit-content;

                    h1 {
                        margin: 0;
        
                    }
        
                    div {
                        display: flex;
                        align-items: flex-end;

                        h4 {
                            display: flex;
                            font-weight: normal;
                            align-items: flex-end;
                        }

                        p {
                            margin: 0;
                        }

                    }

                    p {
                        margin: 0 0.5rem;
                        font-size: large;

                    }

                }

                small {
                    display: flex;
                    text-transform: capitalize;
                    font-size: small;
                    display: flex;
                    color: green;
                    font-weight: bolder;
                    justify-content: right;
                }
    
                
            }
        }

        
    }
}

