.switch-business {
  z-index: 5;
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .name {
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-left: 10px;
      transition: all 0.5s;
    }
  }
  p {
    cursor: pointer;
    padding: 7px 5px;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    &:hover {
      background: #979696a3;
      
      border-radius: 5px;
    }
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 20px 16px;
    z-index: 1;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover {
    img {
      transform: rotate(270deg);
    }
  }
  
}