.email-template {
  width: 80%;
  max-width: 600px;
  background: #fff;
  margin: 50px auto;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  button {
    width: 50px;
    position: absolute;
    top: 0;
    right: 20px;
  }
  header {
    margin-bottom: 20px;
    min-height: 100px;
    img {
      width: 70px;
      height: 70px;
    }
    p {
      font-size: 14px;
      margin: 2px;
    }
  }
  .generic-img-wrapper {
    background: rgb(2, 176, 186);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .input-wrapper {
      width: 200px;
      margin: auto;
    }
    img {
      width: 100%;
      height: 200px;
    }
  }
  .body {
    width: 100%;
    min-height: 300px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    textarea {
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      resize: none;
    }
    .customer-name {
      margin: 0;
      padding: 0;
    }
  }
  footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    div {
      margin: 0 10px;
    }
    .socia-icon {
      height: 30px;
      width: 30px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 5px;
    }
  }
  .powered-by {
    width: 70%;
    margin: auto;
    margin-top: 50px;
    a {
      text-decoration: none;
    }
  }
}