.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0%;
  .controls {
    display: flex;
    button {
      margin-right: 10px !important;
    }
  }
}