.businesses {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  .business-card {
    z-index: 1;
    border: 1px solid #9191983e;
    height: 100px;
    border-radius: 5px;
    margin: 10px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // box-shadow: 0px 2px 4px 0px rgba(149, 157, 165, 0.4);
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.4) 0px 1px 2px;
    }
    .bc-overlay {
      position: absolute;
      top: 0;
      box-sizing: border-box;
      left: 0;
      background: #fff;
      opacity: 0.1;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      z-index: 0;
      margin: 0;
    }
    .delete-img {
      display: none;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &:hover {
      .delete-img {
        display: block;
      }
    }
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      width: 40%;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      img {
        width: 70px;
        height: 70px;
        object-fit: contain;
        border-radius: 5px;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;

      p {
        &.name {
          font-size: 20px;
          width: 80%;
          font-weight: 600;
          text-align: left;
          text-transform: uppercase;
          word-wrap: break-word;
        }
        &.rc {
          font-size: 12px;
          font-weight: 800;
        }
      }
    }
  }
}

.notFound {
  align-self: center;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .businesses {
    justify-content: center;
    align-items: center;
    padding: 6% 4%;
  }
}

@media only screen and (min-width: 600px) {
  .businesses {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2% 4%;
  }
}