.create-expense {
  width: 90% !important;
  max-width: 500px !important;
  min-height: 55vh !important;
  height: fit-content;
  background: #FFF;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 30px;
  box-sizing: border-box;
  position: relative;
  h3 {
    padding-top: 0 !important;
  }

  
  .user-group-cont {
    button {
      margin: 0 !important;
    }
    .button-options {
      padding: 0 !important;
    }
    .user-group-wrapper {
      min-height: 100px;
      max-height: 300px !important;
      overflow: auto;
      padding: 20px 0;
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      button {
        width: 100%;
      }
    }
  }
  .add-participant-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    input {
      width: 80%;height: 45px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 10px;
      border: 1px solid #F0F1F7;
      background: #FCFDFE !important;
      color: #66676d;
      font-size: 14px;
    }
    .genericBtn {
      margin-top: 0px !important;
      margin-left: 10px;
    }
  }
  .particpant-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1px 0;
    height: 40px;
    button {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    p {
      width: 60%;
      overflow: hidden;
      text-align: left;
      text-align: left;
      &.logo {
        text-transform: uppercase;
        font-size: 24px;
        margin-right: 10px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        font-weight: 600;
      }
    }
    input {
      width: 20%;
      height: 30px;
      padding: 0 5px;
    }
  }
}

