.nqr-wrapper {
  padding: 2% 4%;
  .atn-btn {
    max-width: max-content;
  }
  .bank-input-wrapper {
    width: 100%;
  }
  .has-nqr {
    width: 100%;
    .qrcard-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: flex-start;
      flex-wrap: wrap;
    }
  }
  .no-nqr {
    width: 100%;
  }
  .qrcode-wrapper {
    cursor: pointer;
    margin: 5px;
    width: 200px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .qrcode {
      width: 100%;
      height: 180px;
    }
    .details {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      h6 {
        padding: 0;
        margin: 0;
      }
    }
  }
  .modal-qr {
    padding-bottom: 20px;
  }
}