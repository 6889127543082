.genericBtn {
  cursor: pointer;
  height: 40px !important;
  font-size: 14px;
  text-transform: capitalize;
  border: 0;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.flat {
  width: 100%;
  background-color: #0898A0; //#664229
  color: #FFFFFF;
  margin: 30px 0;
}

.text {
  color: #0898A0; //#664229
  background: transparent;
  margin: 0;
}

.contained {
  border: 1px solid #0898A0;
  color: #0898A0;
  background: transparent;
  margin: 30px 0;
}