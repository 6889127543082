.dashboard-comp-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  height: max-content;
  width: 100%;
  box-sizing: border-box;
  background: #F9F9F9;
  .comp-wrapper-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // height: 60px;
    // padding: 0 2%;
    box-sizing: border-box;
    background-color: #fff;
    h2 {
      text-transform: capitalize;
      text-align: left;
      @media only screen and (max-width: 769px) {
        font-size: 18px !important;
      }
      
      
      @media only screen and (min-width: 769px) {
        .profile-card {
          flex-direction: row;
        }
      }
    }
    img {
      height: 25px;
      width: 25px;
      object-fit: contain;
    }

    button {
      width: max-content;
    }
  }
  .children-wrapper {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .top-nav {
    // height: 60px;
    // padding: 0 2%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // background: #ece0d1;
    // z-index: 0;
    h4 {
      width: 100% !important;
      text-align: left;
    }
    .sub-nav-wrapper {
      width: 90%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      box-sizing: border-box;
    }
  }
}

.title-wrapper {
  display: flex;
  width: max-content;
  justify-content: flex-start;
  align-items: center;
  .custom-help-wrapper {
    margin-left: 5px;
    background-color: transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 20px;
      height: 20px;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .custom-help {
    font-size: 14px;
    width: 230px;
    height: 150px;
    border-radius: 8px;
    background: #fff !important;
    position: absolute;
    opacity: 1 !important;
    left: -100px;
    top: 45px;
    z-index: 9999999;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    &::before {
      background: #fff;
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      transform: rotate(45deg);
    }
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}