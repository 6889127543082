.create-expense {
  width: 100% !important;
  max-width: 500px !important;
  min-height: 55vh !important;
  height: fit-content;
  background: #FFF;
  border-radius: 5px;
  margin: 20px auto;
  box-sizing: border-box;
  position: relative;
  .custom-wrapper {
    text-align: left;
    label {
      margin-bottom: 50px !important;
      color: #66676D;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
  h3 {
    padding-top: 0 !important;
  }
  
  .user-group-cont {
    button {
      margin: 0 !important;
    }
    .button-options {
      padding: 0 !important;
    }
    .user-group-wrapper {
      min-height: 100px;
      max-height: 300px !important;
      overflow: auto;
      padding: 20px 0;
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      button {
        width: 45% !important;
      }
    }
  }
  .add-participant-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    input {
      width: 80%;height: 45px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 10px;
      border: 1px solid #F0F1F7;
      background: #FCFDFE !important;
      color: #66676d;
      font-size: 14px;
    }
    .genericBtn {
      // margin-top: 0px !important;
      margin-left: 10px;
    }
  }
  
.particpant-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  margin: 1px 0;
  height: 40px;
  input {
    margin: 0 5px;
    width: 40px !important;
    &::after {
      content: '%';
    }
  }
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    height: 100%;
  }
  button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    &.share {
      width: max-content;
    }
  }
  p {
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-align: left;
    &.logo {
      text-transform: uppercase;
      font-size: 20px;
      margin-right: 10px;
      padding: 2px;
      border: 1px solid #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 30px !important;
      height: 30px !important;
      border-radius: 4px;
      font-weight: 600;
    }
    &.email {
      width: 80% !important;
    }
  }
  input {
    width: 20px !important;
    height: 30px;
    padding: 0 5px;
  }
}
}

@media only screen and (max-width: 600px) {
  .create-expense {
    // padding: 10px 15px !important;
  }
}

@media only screen and (min-width: 600px) {
  .create-expense {
    // padding: 10px 30px !important;
  }
}


.secondary-btn {
  background-color: transparent;
  color:#836A69;
  border: 1px solid #836A69;
}

.cancel-action-btn {
  position: absolute;
  right: 10px;
  top: -20px;
  width: 40px;
}

.empty-part-text {
  width: 70%;
  opacity: 0.8;
  line-height: 24px;
  font-size: 12px;
  margin: auto;
}
