.add-user-modal {
  padding: 0;
  h3 {
    padding: 0;
    margin: 0;
  }
  .add-participant-wrapper {
    width: 100% !important;
    .display {
      margin-top: -40px;
    }
  }
  .btn-group {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .input-wrapper {
    button {
      width: min-content !important;
      margin-top: 10px !important;
    }
  }
}

.add-participant-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  input {
    width: 80%;height: 45px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 10px;
    border: 1px solid #F0F1F7;
    background: #FCFDFE !important;
    color: #66676d;
    font-size: 14px;
  }
  .genericBtn {
    // margin-top: 0px !important;
    margin-left: 10px;
  }
}

.particpant-wrapper {
display: flex;
flex-direction: row;
justify-content: space-between !important;
align-items: center;
margin: 1px 0;
height: 40px;
input {
  margin: 0 5px;
  width: 40px !important;
  &::after {
    content: '%';
  }
}
div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 100%;
}
button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  &.share {
    width: max-content;
  }
}
p {
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-align: left;
  &.logo {
    text-transform: uppercase;
    font-size: 24px;
    margin-right: 10px;
    padding: 2px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 600;
  }
  &.email {
    width: 100% !important;
  }
}
input {
  width: 20%;
  height: 30px;
  padding: 0 5px;
}
}

.user-group-cont {
  button {
    margin: 0 !important;
  }
  .button-options {
    padding: 0 !important;
  }
  .user-group-wrapper {
    min-height: 100px;
    max-height: 300px !important;
    overflow: auto;
    padding: 20px 0;
    &:nth-child(odd) {
      .logo {
        background: #FFDFDF;
      }
    }
    &:nth-child(even) {
      .logo {
        background: #CDF3FF;
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    button {
      width: 100%;
    }
  }
}
