
body {
  margin: 0;
  padding: 0;
  // to remove tage overflow
  overflow-x: hidden;
  font-family: 'Onest', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  opacity: 0.8;
  transition: opacity 0.3s;
  &:disabled {
    opacity: 0.2;
    cursor: no-drop;
  }
}

button:hover {
  opacity: 1;
  &:disabled {
    opacity: 0.2;
  }
}

input{
  outline-color: #0898A0 !important;
}


