.multi-select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid red;
  .dropdown-heading {
    button {
      height: 20px !important;
      width: 20px !important;
    }
  }
  .dropdown-heading-value {
    text-align: left !important;
    span {
      font-size: 14px !important;
      color: #000 !important;
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      overflow: visible !important;
    }
  }

  li {
    color: #000 !important;
    label {
      color: #000 !important;
      input {
        width: 15px !important;
        height: 15px !important;
      }
      span {
        color: #000 !important;
        font-size: 14px;
      }
    }
  }
  .custom-value {
    // width: 300px !important;
    margin: 0 5px;
    padding: 5px;
    border-radius: 5px;
    background: rgb(167, 160, 177);
    display: flex !important;
    flex-direction: row !important;
  }
}

.custom-select {
  width: 100%;
  position: relative;
  .input-wrapper {
    position: relative;
    button {
      position: absolute;
      width: auto;
      right: 3px;
      top: 2.5px;
    }
    input {
      width: 100% !important;
      border: 1px solid #b4b4b5 !important;
      padding-right: 50px !important;
      border-radius: 4px !important;
    }
    input::placeholder {
      color: #363639 !important;
    }
  }
  .display {
    top: 50px;
    margin-top: 10px;
    z-index: 9999;
    position: absolute;
    background: #fff;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    height: 150px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .result {
      display: flex;
      margin: 5px 0;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      height: 30px;
      div {
        width: 70%;
        text-align: left;
        word-wrap: break-word;
      }
      button {
        width: max-content !important;
        height: 30px;
      }
    }
  }
}
.invite-user-wrapper {
  width: 100%;
}
