.create-transaction {
  .custom-wrapper {
    margin-bottom: 20px !important;
  }
  .share-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    button {
      width: 100px;
      margin: 10px 20px;
    }
  }
}

.orders {
  width: 100%;
  height: 80vh;
  padding: 1% 4%;
  box-sizing: border-box;
  .title {
    width: 100%;
    text-align: left;
    font-weight: 900;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}