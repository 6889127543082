.add-user-modal {
  padding: 0;
  h3 {
    padding: 0;
    margin: 30px 0;
    text-align: center;
  }
  .btn-group {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .input-wrapper {
    button {
      width: min-content !important;
      margin-top: 10px !important;
    }
  }
}