.categories {
  display: flex;
  padding: 1% 0%;
  justify-content: space-around;
  width: 90%;
  margin: 0 auto;
  list-style: none;
  text-transform: capitalize;
  overflow: auto !important;

  .wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: max-content;
    flex-wrap: wrap;
  }

  .accordion {
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 6px;
    box-sizing: border-box;
  }

  .accordion-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25rem;
    background: transparent;
    border: 2px solid teal;
    cursor: pointer;

    .icon {
      height: 30px;
      width: 30px;
      border: 2px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      box-sizing: border-box;
      border-radius: 5px;
    }

    &:hover {
      background-color: rgba(50, 126, 126, 0.704);
      transition: all 0.2s ease-in-out;
    }

    .title {
      text-align: left;
      margin: 0;
      width: 100%;
      font-size: 16px !important;

      .title-btn {
        .actions-wrap {
          display: flex;
          margin-right: 1rem;

          p {
            margin-right: 1rem;
            font-weight: 400;
          }

          p:last-child {
            margin-right: 0;
          }
        }

        position: relative;
        font-weight: 600;
        display: flex;
        width: 100%;
        background-color: #b5bdc7;
        align-items: center;
        justify-content: space-between !important;
        border: none;
        padding: 1rem 1.6rem;
        cursor: pointer;
        user-select: none;

        .group-detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          p {
            text-transform: capitalize;
          }
        }

        .members-count {
          margin-right: 5px;
        }

        &::after {
          position: absolute;
          content: "";
          width: 1.1rem;
          height: 1.1rem;
          right: 10px;
          background-image: url("../../../assets/down_arrow.png");
          background-size: 100%;
          background-repeat: no-repeat;
          transition: transform 0.2s ease-in-out;
        }
      }
    }

    .cate-actions {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-self: flex-end;

      button {
        height: 30px;
        width: 30px;
        cursor: pointer;
        padding: 0 !important;
        margin: 0 !important;
        margin-left: 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          font-weight: 600;
        }

        img {
          width: 15px;
          height: 15px;
          object-fit: cover;
        }
      }
    }

    .content-wrapper {
      transition: height 0.2s ease-in-out;
      overflow: hidden;

      .content {
        padding: 0;
        margin: 0;
      }
    }
  }

  .subcategories-wrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
    list-style: none;
    padding: 0 1rem;
    margin: 1rem 0;

    .subcategory {
      width: 90%;
      display: flex;
      align-items: center;
      background-color: #ece0d1;
      border-radius: 0.25rem;
      padding: 0 1rem;
      margin: 0 auto 0.6rem auto;

      p {
        text-align: left;
        display: flex;
        flex: 1;
      }

      .subcategory-actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        margin-left: 1rem;

        p {
          cursor: pointer;

          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }

  .accordion-item:not(:first-of-type) {
    border-top: none;
  }

  .active .title-btn::after {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }

  .group-list {
    list-style-type: none;
    padding: 0;
    max-height: 30vh;
    overflow: scroll;
  }

  .user-profile {
    display: flex;
    width: 95%;
    flex-direction: row;
    border-radius: 0.4rem;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: #ffffff;
    border: solid 1px #ece0d1;

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.37) 0px 8px 24px;
      transition: all ease-in-out 0.2s;
    }

    .user-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 50%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      p {
        color: #252733;
        margin: 0;
        text-transform: capitalize;
      }

      small {
        color: #9fa2b4;
        margin: 0;
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        font-size: 12px;
        font-weight: 900;
      }
    }
  }

  .add-btn {
    display: flex;
    overflow: hidden;
    cursor: pointer;
    background: rgba(80, 44, 43, .882);
    width: fit-content;
    height: fit-content;
    padding: 0.2rem 0.5rem;
    align-items: center;
    margin: 0.5rem auto auto;

    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
      margin-right: 0.6rem;
    }

    p {
      font-weight: 500;
    }
  }

  .usergroup-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      width: max-content !important;
      margin: 10px;
    }
  }
}

.confirm-delete {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }

  button {
    width: 100px;
    margin: 10px;
  }

  .input-wrapper {
    margin: 0;
  }
}

.edit-cat-wrapper {
  form {
    width: 100% !important;
  }
}

.edit-category-btn-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    width: 45% !important;
  }
}

.particpant-wrapper {
  &.admin {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}

.subcategory-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border: 1px solid rgba(46, 38, 38, 0.573);
  border-radius: 5px;
  padding: 2px;

  .cate-actions {
    display: flex;
  }

  button {
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      font-weight: 600;
    }

    img {
      width: 15px;
      height: 15px;
      object-fit: cover;
    }
  }

  input {
    display: flex;
    flex: 1;
    height: 30px;
    padding: 0 5px;
    border: 0;

    &.edit {
      border: 1px solid #000;
    }
  }
}