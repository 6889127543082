.users {
  padding: 0 0.5rem 0 0;
  max-height: 55vh;
  overflow: auto;
  .user {
    margin-bottom: 1rem;
    border-radius: 0.4rem;
    min-height: 4rem;
    align-items: center;
    padding: 0.6rem;
    cursor: pointer;
    background-color: #FFFFFF;
    border: solid 1px #ece0d1;
    box-sizing: border-box;
    width: 270px;

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.37) 0px 8px 24px;
      transition: all ease-in-out 0.2s;
    }

    .user-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      position: relative !important;
      padding: 0;

      .user-image {
        width: 2.8rem;
        height: 2.8rem;
        background-color: #ece0d1;
        border-radius: 10%;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        h1 {
          margin: 0;
          padding: 0;
          text-align: center;
          color: #FFFFFF;
          text-transform: uppercase;
          font-size: 42px;
          font-weight: bold;
        }
      }

      .user-info {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .payment {
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            margin-left: 5px;
            margin-top: 5px;
            color: #828491;
            font-size: 12px;
          }
        }
        h5 {
          color: #252733;
          margin: 0;
          text-transform: capitalize;
        }

        small {
          color: #9fa2b4;
          margin: 0;
          height: 15px;
          font-size: 12px;
          line-height: 12px;
          &.email {
            overflow: hidden;
          }
        }
        p {
          height: 15px;
          font-size: 12px;
          margin: 0 !important;
        }
        .options-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
        button {
          height: 30px;
          margin: 0;
          padding: 5px 10px;
          width: max-content;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .payment-status {
        width: fit-content;
        background-color: green;
        border-radius: 0.3rem;
        padding: 0.1rem 0.6rem;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 5px;
        font-size: x-small;
        color: #FFFFFF;
      }

      .unpaid {
        background-color: orange;
      }
    }
    .user-options {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .options-close-btn {
        width: 25px;
        height: 25px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        img {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }
    }
  }
}