
.c-expense-details {
    display: flex;
    height: 100%;
    position: relative;

    .left {
        display: flex;
        flex-direction: column;
        text-align: left;
        height: 100%;
        
        h3 {
            color: #252733;
            margin-bottom: 2rem;
        }
    }

    .right {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        background-color: #FFFFFF;
        border-radius: 1rem;
        box-sizing: border-box;
        

        .right-wrapper {
            padding: 0;
            text-align: left;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            .details-options {
                border: 1px solid #ece0d1;
                background: #fff;
                position: absolute;
                top: 30px;
                right: 50px;
                padding-right: 10px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                button {
                    width: 120px;
                    height: 40px;
                    margin-left: 10px;
                    cursor: pointer;
                    display: flex;
                    justify-content: justify-content;
                    align-items: center;
                    img {
                        width: 15px !important;
                        height: 15px !important;
                        object-fit: contain;
                        margin: 0 5px;
                    }
                    span {
                        width: 100%;
                    }
                }
                .options-close-btn {
                    width: max-content !important;
                    height: 40px !important;
                    justify-content: center;
                    align-self: flex-end;
                    position: absolute;
                    right: -50px;
                    top: -50px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .heading {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-direction: column;

                h1 {
                    color: #252733;
                    margin: 0 1.5rem 0 0;
                    text-transform: capitalize;
                }
                
                h6 {
                    margin-top: 0 !important;
                }
            }

            
    
            small {
                text-transform: capitalize;
                color: #9fa2b4;
                font-weight: 600;

                :last-of-type {
                    
                }
            }

            p {
                font-size: medium;
                color: #252733;
                line-height: 1.4;

                &::first-letter {
                    text-transform: capitalize;
                }

            }
    
            .expense-percentage {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 1rem;
                background-color: #F8FBFF;
                width: fit-content;
                padding: 1rem 2rem;
                border-radius: 1rem;

                h4 {
                    margin: 0 0 0.8rem 0;
                    text-align: left;
                    font-weight: 200;
                }

                .expense-amount {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    margin-bottom: 0.5rem;
                    width: fit-content;

                    h1 {
                        margin: 0;
        
                    }
        
                    div {
                        display: flex;
                        align-items: flex-end;

                        h4 {
                            display: flex;
                            font-weight: normal;
                            align-items: flex-end;
                        }

                        p {
                            margin: 0;
                        }

                    }

                    p {
                        margin: 0 0.5rem;
                        font-size: large;

                    }

                }

                small {
                    display: flex;
                    text-transform: capitalize;
                    font-size: small;
                    display: flex;
                    color: green;
                    font-weight: bolder;
                    justify-content: right;
                }
    
                
            }
        }
        
    }
}
