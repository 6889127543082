.withdrawal-container {
  width: 100%;
  margin: 30px auto;
  padding: 0 8%;
  box-sizing: border-box;
  .withdrawal-table {
    i {
      font-size: 10px;
    }
  }
  .withdraw-card {
    box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.25);
  }
  td {
    text-align: left !important;
  }

  .sn {
    width: 5%;
  }
  .title {
    width: 35%;
    text-transform: capitalize;

  }
  .amount {
    width: 20%;
  }
  .status {
    width: 15%;
    text-align: left;
  }
  .action {
    width: 20%;

  }
}