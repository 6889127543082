@import "/src/utils/breakpoints";
#builder-hero {
    background-image: url('../../../assets/new/productHeroBg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background: linear-gradient(110.83deg, #042527 27.89%, #053234 37.35%, #074347 50.55%, #0E868D 100%);
    @include mobile {
        background-image: url('../../../assets/new/productImgs/mobileHero.png');
    }
}