.share-component {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .share-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: max-content !important;
  }
}