@import "/src/utils/breakpoints";

.App {
  text-align: center;
  padding: 0;
  margin-top: 0;
  position: relative;
}

.react-select-container {
  height: 45px !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: 1px solid #F0F1F7 !important;
  background: #FCFDFE !important;
  color: #66676d;
  margin-top: 5px;
}

.react-select-container > div:first-of-type {
  height: 100% !important;
  border: 0 !important;
  border-radius: 4px;
  border: 1px solid #b4b4b5 !important;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  margin-bottom: 20px;
  .step {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    border: 1px solid #000000 !important;
  }
  .dash {
    width: 15px;
    margin: 0 3px;
    height: 1px;
    background: #000000 !important;
  }
  .active {
    background: #000000 !important;
  }
}

.not-found {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  flex: 1;
  p {
    margin: 5px 0;
    font-size: 12px;
  }
  button {
    width: max-content;
    margin: 5px 0;
  }
}

.status-chip {
  margin-right: 5px;
  padding: 2px 5px;
  border-radius: 2px;
  width: 80px !important;
  box-sizing: border-box;
  color: #FCFDFE;
  &.paid {
    background: rgba(0, 128, 0, 0.562);
  }
  &.unpaid {
    background: #D7932F;
  }
}

.expenses {
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
}

.overflow-table-wrapper {
  max-width: 100% !important;
  overflow-x: auto;
  // width: 83vw !important;
  flex: 1 !important;
  @include mobile {
    width: 80vw !important;
  }
}

.overflow-table {
  min-width: 800px;
}

.flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: min-content;
}

.benefit-card {
  // border: 1px solid;
  // border-image: linear-gradient(180deg, #0898A0 0%, rgba(8, 152, 160, 0.19) 100%) 1 / 1px;
  // border-radius: 20px !important;
}

