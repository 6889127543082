.order {
  width: 100%;
  max-width: 300px;
  height: 80px;
  padding: 4px;
  background-color: rgba(180, 13, 0, 0.1);
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  margin: 10px;
  box-sizing: border-box;
  &.isNew {
    justify-content: center;
    align-items: center;
  }
  &:hover {
    opacity: 1;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    &.name {
      font-weight: 800;
      font-weight: 16px;
    }
  }
}