
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1001;
}

.c-pane {
    position: fixed;
    width: 100%;
    max-width: 430px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 0;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    z-index: 1001;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .m-close {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        // background-color: #ECE0D1;
        img {
            cursor: pointer;
            width: 1.3rem;
            height: 1.3rem;
        }
    }

    .modal-footer {
        display: flex;
        width: 100%;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        background-color: #ece0d180;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        button {
            width: 45%;
        }
    }

    .modal-wrapper {
        width: 100%;
        padding: 0px 35px;
        // min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: auto;
        box-sizing: border-box;
        flex: 1;

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 30px;
            h3 {
                color: #000000;
                margin-bottom: 1.5rem;
            }
    
            input, select, textarea {
                width: 100%;
                margin-bottom: 0.5rem;
                outline: none;
    
                &::placeholder {
                    color: #B5BDC7;
                }
            }
    
            button {
                // width: 50%;
                margin: 0;
            }
    
        }
    }

}