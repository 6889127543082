.profile-wrapper {
  width: 100%;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}
.profile-form {
  width: 60%;
  background: #FFF;
  margin-top: 40px;
}

.profile-aside {
  border-radius: 5px;
  width: 25%;
  background: #FFF;
}
.profile-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 80%;
  margin: 50px;
  min-height: 60vh;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.details-left, .details-right {
  min-width: 320;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-left {
  text-align: center;
}

.details-right {
  align-items: flex-start;
}

.img-placeholder, .profile-img {
  width: 150px;
  height: 150px;
  background: #1a1919;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 84px;
  font-weight: bolder;
  border-radius: 100px;
  color: #FFFFFF;
  object-fit: cover;
}


.img-wrapper {
  position: relative;
  width: 150px;
  .file-upload {
    cursor: pointer;
    border-radius: 20px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a1919;
    top: 30px;
    right: 10px;
    input {
      display: none;
    }
  }
}

.detail-username {
  font-size: 24px;
  font-weight: 700;
  display: block;
  margin-top: 20px;
}

.detail-email {
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.details-right .detail {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.detail .detail-title {
  font-size: 16px;
  font-weight: 500;
}

.detail .detail-value {
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 769px) {
  .profile-card {
    flex-direction: column;
  }
  .profile-btn-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  // .input-wrapper {
  //   width: 45%;
  // }
}


@media only screen and (min-width: 769px) {
  .profile-card {
    flex-direction: row;
  }
  // .input-wrapper {
  //   width: 100%;
  // }
}

