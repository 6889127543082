.voucherDashboard {
  width: 100dvw;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: fit-content;
  background-color: #F9F9F9;

  & .outlet {
    flex: 1;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
  }
}