.user-group {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .wrapper {
        display: flex;
        width: 90%;
        flex-direction: column;
        box-sizing: border-box;
        align-self: center;
        align-content: flex-start;
    }

    .accordion {
        list-style-type: none;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      @media only screen and (max-width: 1024px) {
        justify-content: center;
      }
      
      
      @media only screen and (min-width: 1024px) {
        justify-content: flex-start;
      }
    }

    .accordion-item {
        display: flex;
        width: 90%;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .content-wrapper {
            transition: height 0.2s ease-in-out;
            overflow: hidden;

            .content {
                padding: 0;
                margin: 0;
            }
        }

    }

    .accordion-item:not(:first-of-type) {
        border-top: none;
    }

   .active .title-btn::after {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in-out;

    }

    .group-list {
        list-style-type: none;
        padding: 0;
        max-height: 30vh;
        overflow: scroll;

    }

    .user-profile {
        display: flex;
        width: 95%;
        flex-direction: row;
        border-radius: 0.4rem;
        height: 3rem;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1.5rem;
        margin-bottom: 1rem;
        cursor: pointer;
        background-color: #FFFFFF;
        border: solid 1px #ece0d1;

        &:hover {
            box-shadow: rgba(149, 157, 165, 0.37) 0px 8px 24px;
            transition: all ease-in-out 0.2s;
        }

        .user-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 50%;
            align-items: flex-start;
            justify-content: center;
            text-align: left;

            p {
                color: #252733;
                margin: 0;
                text-transform: capitalize;
            }

            small {
                color: #9fa2b4;
                margin: 0;
            }


        }
        .details {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            p {
                font-size: 12px;
                font-weight: 900;
            }
        }
        
        
    }

    .add-btn {
        display: flex;
        overflow: hidden;
        cursor: pointer;
        background: rgba(80,44,43,.882);
        width: fit-content;
        height: fit-content;
        padding: 0.2rem 0.5rem;
        align-items: center;
        margin: 0.5rem auto auto;


        img {
            width: 2rem;
            height: 2rem;
            object-fit: contain;
            margin-right: 0.6rem;
        }

        p {
            font-weight: 500;
        }
    }

    .usergroup-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        button {
            width: max-content !important;
            margin: 10px;
        }
    }

}

.confirm-delete {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
    }

    button {
        width: 100px;
        margin: 10px;
    }
    .input-wrapper {
        margin: 0;
    }
}

.no-grp {
    width: 100%;
    text-align: center;
}

.grp-card {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    margin: 5px;
    border-radius: 0.25rem;
    background: #CBB397;
    position: relative;
    cursor: pointer;
    .icon {
        width: 50px;
        height: 50px;
        border-radius: 40px;
        background-color: #502c2b6c;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            font-size: 30px;
            padding: 0;
            // margin: 0;
        }
    }
    .group-detail {
        height: 100%;
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
        padding: 0 5px;
        box-sizing: border-box;
        overflow: hidden;
        h3, h5 {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: left;
        }
        h5 {
            margin-bottom: 5px;
        }
        h3 {
            font-size: 14px;
        }
    }
    button {
        position: absolute;
        background-color: transparent;
        height: 30px;
        width: 30px;
        align-self: flex-end;
        // border: 1px solid red;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: -5px;
        right: 0;
        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
        }
    }
}