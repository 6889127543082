@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Onest';
    src: url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");
}

.css-2613qy-menu {
    border: 1px solid red !important;
}

