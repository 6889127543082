.create-expense {
  width: 90% !important;
  max-width: 500px !important;
  min-height: 55vh !important;
  height: fit-content;
  background: #FFF;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 40px;
  box-sizing: border-box;
  position: relative;
  h3 {
    padding-top: 0 !important;
  }
  
  .user-group-cont {
    button {
      margin: 0 !important;
    }
    .button-options {
      padding: 0 !important;
    }
    .user-group-wrapper {
      min-height: 100px;
      max-height: 300px !important;
      overflow: auto;
      padding: 20px 0;
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      button {
        width: 100%;
      }
    }
  }
  .add-participant-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px 0;
    input {
      width: 80%;height: 45px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 10px;
      border: 1px solid #F0F1F7;
      background: #FCFDFE !important;
      color: #66676d;
      font-size: 14px;
    }
    .genericBtn {
      margin-top: 10px !important;
    }
  }
  .particpant-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 3px 0;
    button {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    p {
      width: 60%;
      overflow: hidden;
      text-align: left;
    }
    input {
      width: 20%;
      height: 30px;
      padding: 0 5px;
    }
  }
}

.grps-wrapper {
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.grp-types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  .grp-type {
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 8px;
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background: #ece0d1;
    h3 {
      padding: 0;
      margin: 0;
      padding-bottom: 5px;
      transition: all 0.2s;
      max-width: 85%;
    }
    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      width: 80%;
    }
    &:hover {
      h3 {
        border-bottom: 2px solid #66676d;
      }
    }
  }
}

.ajo-member-form {
  width: 100% !important;
}

.ajo-mem-details {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center !important;
  align-items: flex-start !important;
  p {
    padding: 0 !important;
    margin: 0 !important;
    &:last-child {
      font-size: 12px;
    }
  }
}
