.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .wrapper {
        display: flex;
        width: 50%;
        margin: 2rem auto;
        justify-content: center;
        flex-direction: column;

        .title-wrapper {
            margin-bottom: 2rem;
            height: 80vh;
            margin: auto;

            h2 {
                margin-bottom: 1rem;
            }
        }
    }

}